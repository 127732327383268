<template>
    <navigation-row v-bind="{ items }" />
</template>

<script>
import NavigationRow from "@/components/dumb/navigation-row.vue";
const items = [
    { to: "/community/boards", text: "게시판" },
    { to: "/community/events", text: "이벤트" },
];
export default {
    components: { NavigationRow },
    data: () => ({
        items,
    }),
};
</script>

<style></style>
