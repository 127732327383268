<template>
    <v-btn v-scroll="onScroll" v-bind="$attrs" :style="style" v-on="{ ...($on || {}) }" fab fixed bottom right transition="slide-x-transition" class="fixed-btn">
        <slot />
    </v-btn>
</template>

<script>
export default {
    props: {
        activeScrollY: { type: Number, default: () => window.innerHeight / 2 },
    },
    data() {
        return {
            active: false,
            bottom: false,
        };
    },
    computed: {
        style() {
            let style = "";
            if (this.active) style += "";
            if (this.bottom) style += "";
            return style;
        },
    },
    methods: {
        onScroll() {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            if (scrollTop > this.activeScrollY) {
                if (!this.active) this.active = true;
                this.$nextTick(this.$forceUpdate);
            } else {
                if (this.active) this.active = false;
            }

            var innerHeight = window.innerHeight;
            var scrollHeight = document.body.scrollHeight;
            if (scrollTop + innerHeight >= scrollHeight) {
                if (!this.bottom) this.bottom = true;
            } else {
                if (this.bottom) this.bottom = false;
            }

            var fixedBtn = document.querySelector('.fixed-btn');
            if(fixedBtn !== null){
                if(scrollTop > window.innerHeight/2){
                    fixedBtn.classList.add('active');
                }else {
                    fixedBtn.classList.remove('active');
                }

                var innerHeight = window.innerHeight;
                var scrollHeight = document.body.scrollHeight;
                if (scrollTop + innerHeight >= scrollHeight) {
                    fixedBtn.classList.add('bottom');
                } else {
                    fixedBtn.classList.remove('bottom');
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fixed-btn {
    width: 50px !important;
    height: 50px !important;
    border-radius: 10px !important;
    -webkit-transition: all ease-out .3s; -ms-transition: all ease-out .3s; transition: all ease-out .3s;
    box-shadow: 0 0 10px var(--shadow) !important;
}
.fixed-btn.active{
    margin-bottom: 56px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .fixed-btn.active{
        margin-bottom: 70px;
    }
    .fixed-btn.bottom{
        bottom:246px;
    }
}
@media (min-width:1024px){
    .fixed-btn{
        width: 60px !important;
        height: 60px !important;
        border-radius: 16px !important;
    }
}
</style>
