<template>
    <client-page>
        <sub-visual visual="커뮤니티" subTitle="COMMUNITY" />

        <v-container>
            <community-navigation />

            <v-tabs-items :value="code">
                <v-tab-item value="boards">
                    <router-view name="board" code="boards" skin="board-social" />
                </v-tab-item>
                <v-tab-item value="events">
                    <router-view name="board" code="events" skin="events" />
                </v-tab-item>
            </v-tabs-items>
        </v-container>

        <v-fade-transition>
            <u-fixed-btn v-show="$route.path !== '/community/boards/write' && $route.params.code == 'boards'" color="primary" to="/community/boards/write" :activeScrollY="0" large style="border-radius:16px;">
                <i class="icon icon-pencil"></i>
            </u-fixed-btn>
        </v-fade-transition>
    </client-page>
</template>

<script>
import UFixedBtn from "@/components/dumb/u-fixed-btn.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import CommunityNavigation from "@/components/client/community/community-navigation.vue";

export default {
    components: {
        UFixedBtn,
        SubVisual,
        ClientPage,
        CommunityNavigation,
    },
    props: {
        code: { type: String, default: "boards" },
    },
    mounted() {
        this.syncRoute();
    },
    watch: {
        code() {
            this.syncRoute();
        },
    },
    methods: {
        async syncRoute() {
            const path = `/community/${this.code}`;
            if (this.$route.params.code !== this.code) this.$router.replace({ path });
            if (!this.$route.params.code) this.$router.push(path);
        },
    },
};
</script>
