var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "visual": "커뮤니티",
      "subTitle": "COMMUNITY"
    }
  }), _c('v-container', [_c('community-navigation'), _c('v-tabs-items', {
    attrs: {
      "value": _vm.code
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "boards"
    }
  }, [_c('router-view', {
    attrs: {
      "name": "board",
      "code": "boards",
      "skin": "board-social"
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "events"
    }
  }, [_c('router-view', {
    attrs: {
      "name": "board",
      "code": "events",
      "skin": "events"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', [_c('u-fixed-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.path !== '/community/boards/write' && _vm.$route.params.code == 'boards',
      expression: "$route.path !== '/community/boards/write' && $route.params.code == 'boards'"
    }],
    staticStyle: {
      "border-radius": "16px"
    },
    attrs: {
      "color": "primary",
      "to": "/community/boards/write",
      "activeScrollY": 0,
      "large": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-pencil"
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }