var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._g(_vm._b({
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "fixed-btn",
    style: _vm.style,
    attrs: {
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "transition": "slide-x-transition"
    }
  }, 'v-btn', _vm.$attrs, false), Object.assign({}, _vm.$on || {})), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }